
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const createNotification = (type,message) => {
    switch (type) {
        case 'welcome':
          toast(message)
          break;
        case 'info':
          toast.info(message,{
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
            });
          break;
        case 'success':
          toast.success(message,{
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
          });
          break;
        case 'warning':
          toast.warn(message,{
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
          });
          break;
        case 'error':
          toast.error(message,{
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
          });
          break;
        case 'dark':
          toast.dark(message,{
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
          });
          break;
        default:
          toast(message,{
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
          });
          break;

    }
};


export default createNotification;