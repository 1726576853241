// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "./reducer/search_filter/searchSlice";

export default configureStore({
  reducer: {
    filter: searchSlice,
  },
  
});