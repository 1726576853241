import React, { Suspense, useState } from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import { SETTINGS, AUDIT, CLIENT_MANAGEMENT_CLIENTS, OUTPUT_FILES,DASHBOARD,AUDIT_LOG, ENCOUNTERS, ENCOUNTER_FILES, ENCOUNTER_HTML_VIEW, ENCOUNTER_VIEW_BASED_ON_FILE, IMPORT_ENCOUNTER_FILES, IMPORT_PAYMENTS_FILES, LOGIN, NACHA_FILES, NACHA_PAYMENTS, PAYMENTS, PAYMENTS_FILES, PAYMENT_HTML_VIEW, PAYMENT_VIEW_BASED_ON_FILE, PROFILES, REASSOCIATION_MATCHED_REMITTANCES, REASSOCIATION_ORPHAN, REASSOCIATION_REPORTS, REASSOCIATION_UNMATCHED_REMITTANCES, REPORTS_PAYMENTS, TWO_FACTOR_AUTHENTICATION, USER_MANAGEMENT_CREATE_GROUPS, USER_MANAGEMENT_CREATE_ROLES, USER_MANAGEMENT_CREATE_USERS, USER_MANAGEMENT_GROUPS, USER_MANAGEMENT_ROLES, USER_MANAGEMENT_UPDATE_GROUPS, USER_MANAGEMENT_UPDATE_ROLES, USER_MANAGEMENT_UPDATE_USERS, USER_MANAGEMENT_USERS, IMPORT_NACHA_FILES, BAI_FILES, BAI_PAYMENTS, IMPORT_BAI_FILES, CAPTCHA, SEARCH_PAGE, CUSTOM_ERROR_PAGE_URL, USER_TYPE, NON_REVARC_DASHBOARD,  PAYMENT_835_LITE, PATIENT_PAY_URL, CORRESPONDANCE_URL, LOCKBOX_URL, PATIENT_PAY_VIEW_URL, CORRESPONDANCE_VIEW_URL, PAYMENT_835_LITE_VIEW_URL } from "./constants/routeName";
import PrivateLayout from "./containers/layouts/PrivateLayout";
import '../src/static/css/common.css'
import 'rsuite/dist/rsuite.min.css';
import NonRevarcDashboard from "./views/pages/non-revarc-dashboard/dashboard";


const LOGIN_PAGE = React.lazy(() =>
  import("./views/pages/auth/Login")
);

const DASHBOARD_PAGE = React.lazy(() =>
  import("./views/pages/Dashboard")
);

const ENCOUNTER_FILES_PAGE = React.lazy(() =>
  import("./views/pages/837_management/Files")
);

const IMPORT_ENCOUNTER_FILES_PAGE = React.lazy(() =>
  import("./views/pages/837_management/Import")
);

const ENCOUNTERS_PAGE = React.lazy(() =>
  import("./views/pages/837_management/Encounters")
);

const ENCOUNTER_HTML_VIEW_PAGE = React.lazy(() =>
  import("./views/pages/837_management/HTMLView")
);


const PAYMENT_FILES_PAGE = React.lazy(() =>
  import("./views/pages/835_management/Files")
);

const IMPORT_PAYMENT_FILES_PAGE = React.lazy(() =>
  import("./views/pages/835_management/Import")
);

const PAYMENTS_PAGE = React.lazy(() =>
  import("./views/pages/835_management/Payments")
);

const PAYMENT835_LITE_PAGE = React.lazy(() =>
  import("./views/pages/835_lite_management/payment835lite")
);

const PAYMENT835_LITE_VIEW_PAGE = React.lazy(() =>
  import("./views/pages/835_lite_management/HTMLView")
);



const PATIENT_PAY_PAGE = React.lazy(() =>
  import("./views/pages/patientPayManagement/patientPay")
);

const PATIENT_PAY_VIEW_PAGE = React.lazy(() =>
  import("./views/pages/patientPayManagement/HTMLView")
);
const CORRESPONDANCE_PAGE = React.lazy(() =>
  import("./views/pages/correspondance_management/correspondance")
);
const CORRESPONDANCE_VIEW_PAGE = React.lazy(() =>
  import("./views/pages/correspondance_management/HTMLView")
);
const LOCKBOX_PAGE = React.lazy(() =>
  import("./views/pages/lockbox/files")
);


const PAYMENT_HTML_VIEW_PAGE = React.lazy(() =>
  import("./views/pages/835_management/HTMLView")
);



const NACHA_FILES_PAGE = React.lazy(() =>
  import("./views/pages/ACH_management/Files")
);

const NACHA_PAYMENTS_PAGE = React.lazy(() =>
  import("./views/pages/ACH_management/Payments")
);

const IMPORT_NACHA_FILES_PAGE = React.lazy(() =>
  import("./views/pages/ACH_management/Import")
);




const BAI_FILES_PAGE = React.lazy(() =>
  import("./views/pages/BAI_management/Files")
);

const BAI_PAYMENTS_PAGE = React.lazy(() =>
  import("./views/pages/BAI_management/Payments")
);

const IMPORT_BAI_FILES_PAGE = React.lazy(() =>
  import("./views/pages/BAI_management/Import")
);





const REASSOCIATION_ORPHAN_PAGE = React.lazy(() =>
  import("./views/pages/reassociation/Orphan")
);

const REASSOCIATION_MATCHED_REMITTANCE_PAGE = React.lazy(() =>
  import("./views/pages/reassociation/MatchedRemittances")
);
const REASSOCIATION_UNMATCHED_REMITTANCE_PAGE = React.lazy(() =>
  import("./views/pages/reassociation/UnmatchedRemittances")
);



const REASSOCIATION_REPORTS_PAGE = React.lazy(() =>
  import("./views/pages/reassociation/Reports")
);



const USER_MANAGEMENT_ROLES_PAGE = React.lazy(() =>
  import("./views/pages/roles_management/Roles")
);

const USER_MANAGEMENT_CREATE_OR_UPDATE_ROLES_PAGE = React.lazy(() =>
  import("./views/pages/roles_management/CreateOrUpdateRole")
);


const USER_MANAGEMENT_GROUPS_PAGE = React.lazy(() =>
  import("./views/pages/group_management/Groups")
);

const USER_MANAGEMENT_CREATE_OR_UPDATE_GROUP_PAGE = React.lazy(() =>
  import("./views/pages/group_management/CreateOrUpdateGroup")
);



const USER_MANAGEMENT_USERS_PAGE = React.lazy(() =>
  import("./views/pages/user_management/Users")
);

const USER_MANAGEMENT_CREATE_OR_UPDATE_USERS_PAGE = React.lazy(() =>
  import("./views/pages/user_management/CreateOrUpdateUser")
);



const CLIENT_MANAGEMENT_CLIENTS_PAGE = React.lazy(() =>
  import("./views/pages/client_management/Client")
);



const PROFILES_PAGE = React.lazy(() =>
  import("./views/pages/Profiles")
);



const TWO_FACTOR_AUTHENTICATION_PAGE = React.lazy(() =>
  import("./views/pages/auth/TwoFactorAuth")
);
const CUSTOM_ERROR_PAGE = React.lazy(() =>
  import("./views/pages/error_page/index.js")
);

const OUTPUT_FILES_PAGE = React.lazy(() =>
  import("./views/pages/OutputFiles/index.js")
);


const AUDIT_LOG_PAGE = React.lazy(() =>
  import("./views/pages/audit_management/audit_log")
);


const NOTES_PAGE = React.lazy(() =>
  import("./views/pages/reassociation/Notes")
);

const SETTINGS_PAGE = React.lazy(() =>
  import("./views/pages/settings")
);
const GLOBAL_SEARCH_PAGE = React.lazy(() =>
  import("./views/pages/global_search")
);




const AWS_S3 = React.lazy(() =>
  import("./views/pages/aws_s3")
);
const CAPTCHA_PAGE = React.lazy(() =>
  import("./views/pages/captcha/captcha")
);
const USER_TYPE_PAGE = React.lazy(() =>
  import("./views/pages/user_type/index.js")
);



function App() {
  
  const [heading,setHeading] = useState('')
  const [sync,setSync] = useState('')

  return (
    <Suspense fallback={<></>}>

      <Routes>
        <Route path={LOGIN} element={<LOGIN_PAGE />} exact />
        <Route path={TWO_FACTOR_AUTHENTICATION} element={<TWO_FACTOR_AUTHENTICATION_PAGE />} exact />
        <Route path={USER_TYPE} element={<USER_TYPE_PAGE />} exact />

        <Route path='/*' element={<CUSTOM_ERROR_PAGE />} exact />
        
        
        <Route element={<PrivateLayout heading={heading} sync={sync} />}>


          <Route path={DASHBOARD} element={<DASHBOARD_PAGE setHeading={setHeading} setSync={setSync} />} />
          <Route path={NON_REVARC_DASHBOARD} element={<NonRevarcDashboard setHeading={setHeading} setSync={setSync} />} />
          <Route path={AUDIT} element={<DASHBOARD_PAGE setHeading={setHeading} setSync={setSync} />} />

          <Route path={ENCOUNTER_FILES} element={<ENCOUNTER_FILES_PAGE setHeading={setHeading} />} />
          <Route path={IMPORT_ENCOUNTER_FILES} element={<IMPORT_ENCOUNTER_FILES_PAGE setHeading={setHeading} />} />
          <Route path={ENCOUNTERS} element={<ENCOUNTERS_PAGE setHeading={setHeading} />} />


          <Route path={ENCOUNTER_HTML_VIEW}  element={<ENCOUNTER_HTML_VIEW_PAGE setHeading={setHeading} />} />
          <Route path={ENCOUNTER_VIEW_BASED_ON_FILE}  element={<ENCOUNTERS_PAGE setHeading={setHeading} />} />


          <Route path={PAYMENTS_FILES} element={<PAYMENT_FILES_PAGE setHeading={setHeading} />} />
          <Route path={IMPORT_PAYMENTS_FILES} element={<IMPORT_PAYMENT_FILES_PAGE setHeading={setHeading} />} />
          <Route path={PAYMENTS} element={<PAYMENTS_PAGE setHeading={setHeading} />}  />

          <Route path={PAYMENT_HTML_VIEW}  element={<PAYMENT_HTML_VIEW_PAGE setHeading={setHeading} />} />

          <Route path={PAYMENT_VIEW_BASED_ON_FILE}  element={<PAYMENTS_PAGE setHeading={setHeading} />} />


          <Route path={PAYMENT_835_LITE}  element={<PAYMENT835_LITE_PAGE setHeading={setHeading} />} />
          <Route path={PAYMENT_835_LITE_VIEW_URL}  element={<PAYMENT835_LITE_VIEW_PAGE setHeading={setHeading} />} />



          <Route path={PATIENT_PAY_URL}  element={<PATIENT_PAY_PAGE setHeading={setHeading} />} />
          <Route path={PATIENT_PAY_VIEW_URL}  element={<PATIENT_PAY_VIEW_PAGE setHeading={setHeading} />} />



          <Route path={CORRESPONDANCE_URL}  element={<CORRESPONDANCE_PAGE setHeading={setHeading} />} />
          <Route path={CORRESPONDANCE_VIEW_URL}  element={<CORRESPONDANCE_VIEW_PAGE setHeading={setHeading} />} />


          <Route path={LOCKBOX_URL}  element={<LOCKBOX_PAGE setHeading={setHeading} />} />




          <Route path={NACHA_FILES} element={<NACHA_FILES_PAGE setHeading={setHeading} />} />
          <Route path={NACHA_PAYMENTS} element={<NACHA_PAYMENTS_PAGE  setHeading={setHeading} />} />
          <Route path={IMPORT_NACHA_FILES} element={<IMPORT_NACHA_FILES_PAGE setHeading={setHeading} />} />
          
          <Route path={BAI_FILES} element={<BAI_FILES_PAGE setHeading={setHeading} />} />
          <Route path={BAI_PAYMENTS} element={<BAI_PAYMENTS_PAGE setHeading={setHeading} />} />
          <Route path={IMPORT_BAI_FILES} element={<IMPORT_BAI_FILES_PAGE setHeading={setHeading} />} />



          <Route path={REASSOCIATION_ORPHAN}  element={<REASSOCIATION_ORPHAN_PAGE setHeading={setHeading} />} />
          <Route path={REASSOCIATION_MATCHED_REMITTANCES}  element={<REASSOCIATION_MATCHED_REMITTANCE_PAGE setHeading={setHeading} />} />
          <Route path={REASSOCIATION_UNMATCHED_REMITTANCES}  element={<REASSOCIATION_UNMATCHED_REMITTANCE_PAGE setHeading={setHeading} />} />


          <Route path={REASSOCIATION_REPORTS}  element={<REASSOCIATION_REPORTS_PAGE setHeading={setHeading} />} />

          


          <Route path={USER_MANAGEMENT_ROLES}  element={<USER_MANAGEMENT_ROLES_PAGE setHeading={setHeading} />} />
          <Route path={USER_MANAGEMENT_CREATE_ROLES}  element={<USER_MANAGEMENT_CREATE_OR_UPDATE_ROLES_PAGE setHeading={setHeading} />} />
          <Route path={USER_MANAGEMENT_UPDATE_ROLES}  element={<USER_MANAGEMENT_CREATE_OR_UPDATE_ROLES_PAGE setHeading={setHeading} />} />

          <Route path={USER_MANAGEMENT_GROUPS}  element={<USER_MANAGEMENT_GROUPS_PAGE setHeading={setHeading} />} />
          <Route path={USER_MANAGEMENT_CREATE_GROUPS}  element={<USER_MANAGEMENT_CREATE_OR_UPDATE_GROUP_PAGE setHeading={setHeading} />} />
          <Route path={USER_MANAGEMENT_UPDATE_GROUPS}  element={<USER_MANAGEMENT_CREATE_OR_UPDATE_GROUP_PAGE setHeading={setHeading} />} />
          

          <Route path={USER_MANAGEMENT_USERS}  element={<USER_MANAGEMENT_USERS_PAGE setHeading={setHeading} />} />
          <Route path={USER_MANAGEMENT_CREATE_USERS}  element={<USER_MANAGEMENT_CREATE_OR_UPDATE_USERS_PAGE setHeading={setHeading} />} />
          <Route path={USER_MANAGEMENT_UPDATE_USERS}  element={<USER_MANAGEMENT_CREATE_OR_UPDATE_USERS_PAGE setHeading={setHeading} />} />
          
          <Route path={CLIENT_MANAGEMENT_CLIENTS}  element={<CLIENT_MANAGEMENT_CLIENTS_PAGE setHeading={setHeading} />} />

          <Route path={OUTPUT_FILES}  element={<OUTPUT_FILES_PAGE setHeading={setHeading} />} />

          
          <Route path={PROFILES}  element={<PROFILES_PAGE setHeading={setHeading} />} />

          <Route path={AUDIT_LOG}  element={<AUDIT_LOG_PAGE setHeading={setHeading}  />} />


          <Route path={CAPTCHA}  element={<CAPTCHA_PAGE />} />
          <Route path={SETTINGS}  element={<SETTINGS_PAGE setHeading={setHeading} />} />


          <Route path={SEARCH_PAGE}  element={<GLOBAL_SEARCH_PAGE setHeading={setHeading} />} />
          
          




          





          <Route path="aws-s3" element={<AWS_S3 />} />




        </Route>

      </Routes>
    </Suspense>
  );
}













export default App;
