
const Proxy = (url) => {

    
    return url.replace('http://', 'https://')
    
    // return url
}

export default Proxy
