import { _static_messages } from "src/components/common/Messages";
import createNotification from "src/components/common/NotificationContainer";
import Proxy from "src/components/common/Redirect/Proxy";
import { LOGIN } from "src/constants/routeName";
import { userLogoutApi } from "src/context/actions/auth/login/Login";
import { exportAllFile, sendRemittenceMail } from "src/context/actions/common/common";

export const isLogin = () => {
    if (localStorage.getItem('token')) {
        return true;
    }
    return false;
}




export const logOut = (navigate) => {
    const _data = new FormData();
    _data.append("refresh", localStorage.getItem('refresh'));
    userLogoutApi({ data: _data })((response) => {
        if (response?.data?.status) {
            localStorage.clear()
            sessionStorage.clear()
            navigate(LOGIN)

        }
    })
}

export const exportFile = (props) => {
    props?.setLoading(true)
    exportAllFile(props)((response) => {
        if (response?.data?.file_url?.url) {
            if (props?.is_mail_send) {
                if (response?.data?.status == 200) {
                    createNotification('success', _static_messages.SEND_MAIL)
                    if(props?.setUpdate && props?.setUpdate !== undefined){
                        props.setUpdate(!props?.update)
                    }
                    // window.location.reload()
                }
                else {
                    createNotification('error', _static_messages.DEFAULT)
                }
            }
            else {
                // debugger;
                window.open(Proxy(response?.data?.file_url?.url), "_blank")

            }

        }
        else {
            if (props?.is_mail_send) {
                createNotification('error', 'No data available.')
            }
            else {
                createNotification('error', 'No data available.')
            }
        }
        props?.setLoading(false)
    })
}
export const sendFile = (props) => {
    sendRemittenceMail(props)((response) => {
        if (response) {
            if (response?.status == 200) {
                createNotification('success', _static_messages.SEND_MAIL)
            }
            // window.open(Proxy(response?.data?.file_url?.url), "_blank")

        }
    })
}


export const searchOnEnter = (e, tableRef) => {
    if (e.keyCode === 13) {
        tableRef.current && tableRef.current.onQueryChange();
    }
}




const handleFileChange = (event) => {
    return new Promise((resolve, reject) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const base64String = e.target.result.split(',')[1];
            resolve(base64String);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};




export const convertFileToBase64 = async (event) => {

    try {
        const base64String = await handleFileChange(event);

        return base64String


    } catch (error) {
        // Handle the error
        console.error(error);
        return ''
    }

};


