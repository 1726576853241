import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  s__key: {},
  s__key_2: {},
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchDetails: (state, action) => {
      state.s__key = action.payload;
    },

    setClearSearchDetails: (state) => {
        state.s__key = {};
        state.s__key_2 = {};
    },
    setSearchDetails2: (state, action) => {
      state.s__key_2 = action.payload;
    },

    setClearSearchDetails2: (state) => {
        state.s__key_2 = {};
      
    },
  },
});

export const { setClearSearchDetails, setSearchDetails,setSearchDetails2,setClearSearchDetails2 } = searchSlice.actions;

export default searchSlice.reducer;