import React, { useEffect, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import { format } from 'date-fns'
import { Card, Form, Modal } from 'react-bootstrap'
import { TextField, } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Clear, Refresh, Search } from '@material-ui/icons';
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import DataTable from 'react-data-table-component';
import ReactiveButton from 'reactive-button'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchDetails, setSearchDetails2 } from 'src/reducer/search_filter/searchSlice'
import { getNonRevarcData } from 'src/context/actions/dashboard'
import { _static_messages } from 'src/components/common/Messages'
import createNotification from 'src/components/common/NotificationContainer'
import { PAYMENT_HTML_VIEW } from 'src/constants/routeName'
import { exportFile } from 'src/helpers/utils'
import Flatpickr from "react-flatpickr";
import { algo } from 'crypto-js'

const NonRevarcDashboard = ({ setHeading }) => {
    const { DateTime } = require("luxon");
    const dispatch = useDispatch()
    const state = useSelector((state) => state?.filter)
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(null)
    const [searchedStatus, setSearchedStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const flatpickrUploadRef = React.useRef(null)

    const [popup, setPopup] = useState({
        view: false,
        reason: '',
        data: '',
        type: ''
    });

    const nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [search, setSearch] = useState({
        date: [new Date(), new Date()],
        is_exclude: false,
        client_name: '',
        facility_name: '',
        lockbox_no: '',
        errorField: {},
        sort_order: '',
        sort_key: '',
    })
    const [searchedKeys, setSearchedKeys] = useState({
        date: [new Date(), new Date()],
        is_exclude: false,
        client_name: '',
        facility_name: '',
        lockbox_no: '',
        errorField: {},
        sort_order: '',
        sort_key: '',
    })

    ///////////////////////////////////////datatable functions ////////////////////////////////////

    const [pageno, setPageno] = useState(1);
    const [limit, setLimit] = useState(25);
    const [update, setUpdate] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [colorRow, setColorRow] = useState({});

    useEffect(() => {
        setHeading('File Monitoring')
    }, [])
    useEffect(() => {
        getFileMonitoringApi({ page: pageno, pageSize: limit })
    }, [update])

    const getFileMonitoringApi = (query) => {
        setLoading(true)

        getNonRevarcData({ query: query, search: searchedKeys })(response => {
            if (response.data?.status_code === 200) {

                setData(response?.data)
                setTotalRows(response?.data?.data?.count)
                setLoading(false)
            } else {
                createNotification('error', _static_messages.DEFAULT)
                setLoading(false)
            }
        })
    }
    const [datatable, setDatatable] = useState(true);
    const convertUTCtoLocalTime = (date) => {
        // Parse the provided date and time string as UTC
        let convertedDate = ''
        if (date) {
            const providedDateTime = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });
            // Convert the given time to system's local time
            const localTime = providedDateTime.setZone("local");
            // Format the local time as desired
            convertedDate = localTime.toFormat('MM-dd-yyyy hh:mm:ss a');
        }
        return convertedDate
    }

    const columns = useMemo(
        () => [
            {
                id: 'partner',
                sortable: true,
                name: 'Partner Name',
                selector: (row) => row?.partner,
                cell: (row) => <Tooltip title={row?.partner} disableInteractive><div className='text-truncate' style={{ maxWidth: '250px' }} onClick={() => handleRowClick(row)}>
                    <span> {row?.partner}</span>
                </div>
                </Tooltip>,
                width: "200px",
            },
            {
                id: 'client_name',
                sortable: true,
                name: 'Client Name',
                selector: (row) => row?.client_name,
                cell: (row) => <Tooltip title={row?.client_name} disableInteractive><div className='text-truncate' onClick={() => handleRowClick(row)}>{row?.client_name}</div></Tooltip>,
                minWidth: "150px",
            },
            {
                id: 'facility',
                sortable: true,
                name: 'Facility Name',
                selector: (row) => row?.facility,
                cell: (row) => <Tooltip title={row?.facility} disableInteractive><div className='text-truncate' onClick={() => handleRowClick(row)}>{row?.facility}</div></Tooltip>,
                minWidth: "150px",
            },

            {
                id: 'lockbox_name',
                sortable: true,
                name: 'Lockbox Name',
                selector: (row) => row?.lockbox_name,
                cell: (row) => <Tooltip title={row?.lockbox_name} disableInteractive><div className='text-truncate' style={{ maxWidth: '250px' }} onClick={() => handleRowClick(row)}>
                    <span> {row?.lockbox_name}</span>
                </div>
                </Tooltip>,
                width: "200px",
            },
            {
                id: 'deposit_date',
                sortable: true,
                name: 'Deposit Date',
                selector: (row) => row?.deposit_date,
                cell: (row) => row.deposit_date ? <span onClick={() => handleRowClick(row)}>{format(new Date(row.deposit_date), 'MM/dd/yyyy')}</span> : '',
                width: "120px",
            },
            {
                id: 'lockbox_file_name',
                sortable: true,
                name: 'Lockbox File Name',
                selector: (row) => row?.lockbox_file_name,
                cell: (row) => <Tooltip title={row?.lockbox_file_name} disableInteractive><div className='text-truncate' onClick={() => handleRowClick(row)}>{row?.lockbox_file_name}</div></Tooltip>,
                minWidth: "200px",
            },
            {
                id: 'no_of_files',
                sortable: true,
                name: '# Files',
                cell: (row) =>
                    <span style={{ textAlign: 'right', marginRight: '10px' }} onClick={() => handleRowClick(row)}>
                        {row.no_of_files}
                    </span>
                ,
                width: "80px",
                right: true,
            },
            {
                id: 'no_of_jobs',
                sortable: true,
                name: '# Jobs',
                cell: (row) =>
                    <span style={{ textAlign: 'right', marginRight: '10px' }} onClick={() => handleRowClick(row)}>
                        {row.no_of_jobs}
                    </span>
                ,
                width: "80px",
                right: true,
            },
            {
                id: 'inprogress_jobs',
                sortable: true,
                name: '# Pending',
                cell: (row) =>
                    <span style={{ textAlign: 'right', marginRight: '10px' }} onClick={() => handleRowClick(row)}>
                        {row.inprogress_jobs}
                    </span>
                ,
                width: "110px",
                right: true,
            },
            {
                id: 'completed_jobs',
                sortable: true,
                name: '# Completed',
                cell: (row) =>
                    <span style={{ textAlign: 'right', marginRight: '10px' }} onClick={() => handleRowClick(row)}>
                        {row.completed_jobs}
                    </span>
                ,
                width: "120px",
                right: true,
            },
            {
                id: 'lockbox_arrival_time',
                sortable: true,
                name: 'Lockbox Arrival Time',
                width: "180px",
                selector: (row) => row?.lockbox_arrival_time,
                cell: (row) => <Tooltip title={convertUTCtoLocalTime(row?.lockbox_arrival_time)} disableInteractive><div className='text-truncate' onClick={() => handleRowClick(row)}>{convertUTCtoLocalTime(row?.lockbox_arrival_time)}</div></Tooltip>,
            },
            {
                id: 'expected_tat',
                sortable: true,
                name: 'Expected TAT',
                width: "160px",
                selector: (row) => row?.expected_tat,
                cell: (row) => <Tooltip title={convertUTCtoLocalTime(row?.expected_tat)} disableInteractive><div className='text-truncate' onClick={() => handleRowClick(row)}>{convertUTCtoLocalTime(row?.expected_tat)}</div></Tooltip>,
            },


        ],
        [searchedKeys, pageno, limit, searchedStatus],
    );


    const onPageChange = (newPage) => {
        setPageno(newPage)
        setUpdate(!update)
    }
    const onPerPageChange = (newPerPage) => {
        setLimit(newPerPage)
        setPageno(1)
        setUpdate(!update)
    }
    const handleRowClick = (row) => {
        setColorRow(row);
    };

    const handleSort = (column, order) => {
        console.log(column, order)
        setSearchedKeys({
            ...searchedKeys,
            sort_key: column?.id,
            sort_order: order,
        })
        setSearch({
            ...searchedKeys,
            sort_key: column?.id,
            sort_order: order,
        })
        setUpdate(!update)
    }
    const conditionalRowStyles = [

        {
            when: row => colorRow.id == row.id ? true : false,
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
            }),
        },


    ];
    const queueDatatableCustomStyles = {
        rows: {
            style: {
                minHeight: '30px',
                fontSize: '0.9em',
                fontColor: '#000000',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px',
                fontWeight: '800',
            },

        },
        headRow: {
            style: {
                minHeight: '30px'
            },

        },
        cells: {
            style: {
                borderRight: '1px solid #dcdcdc',
                paddingLeft: '10px',
                paddingRight: '5px',
            },
        },
    }

    //////////////////////////////////////datatable function end ////////////////////////////////


    const navigateToHtmlView = (row) => {
        if (searchParams.get('rem')) {
            dispatch(setSearchDetails2({ name: 'REMITTANCES', key: searchedKeys, page: pageno, limit: limit, status: searchedStatus }))
        }
        else {
            dispatch(setSearchDetails({ name: 'REMITTANCES', key: searchedKeys, page: pageno, limit: limit, status: searchedStatus }))
        }
        navigate({
            pathname: PAYMENT_HTML_VIEW,
            search: `?${createSearchParams({
                pmt: row.id
            })}`,
        })
    }

    /////////////////////////////////Date functions start //////////////////////////////////

    const formRef = React.useRef(null);
    const dateRef = React.useRef(null);


    const handleDateChange = ({ ist = null, date = null, month = null, type }) => {
        console.log(ist);
        if (date !== 'null' && date !== null) {

            setSearch({ ...search, [type]: ist })
        }
        if (ist && ist?.length) {
            if (ist?.length == 1) {
                setSearch({ ...search, [type]: [ist[0]] })
            }
            else {
                setSearch({ ...search, [type]: [ist[0], ist[1]] })

            }
        }

    }
    const handleClearDate = (type) => {
        setSearch({ ...search, [type]: null, errorField: { ...search?.errorField, [type]: false } })
    };
    ////////////////////////////////////Date functions end /////////////////////////////////

    const handleSearchChange = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value })
    }
    const onSearchClick = (e) => {
        e.preventDefault()
        if (!search?.errorField?.submission_date && !search?.errorField?.created_date) {
            setSearchedKeys(search)
            setSearchedStatus(status)
            setPageno(1)
            setUpdate(!update)
        }
    }
    const handleSelectChange = (selected) => {
        setStatus(selected ?? null)
    }
    const onClearSearch = (e) => {
        e.preventDefault()
        setSearch({
            date: null,
            is_exclude: false,
            client_name: '',
            facility_name: '',
            lockbox_no: '',
            sort_order: '',
            sort_key: '',
            errorField: {}
        })
        setSearchedKeys({
            date: null,
            is_exclude: false,
            client_name: '',
            facility_name: '',
            lockbox_no: '',
            sort_order: '',
            sort_key: '',
            errorField: {}
        })
        setStatus(null)
        setSearchedStatus(null)
        setPageno(1)
        setUpdate(!update)
    }
    const handleClearSearchData = (type) => {
        setSearch({ ...search, [type]: "" })
    }
    const submitReason = () => {
        if (popup?.reason == '' || popup?.reason == undefined) {
            setPopup({ ...popup, error: true })
            return
        }
        if (popup?.type == 'EXPORT') {
            exportFile({ url: 'payment/get-payments?is_file=1&', model_number: 4, type: "csv", master: searchParams.get('rem'), search: searchedKeys, matched: searchedStatus?.value, setLoading: setLoading, reason: popup?.reason })
        }
        setPopup({
            view: false,
            reason: '',
            data: '',
            type: ''
        })
    }
    console.log(search)
    return (
        <div className="container-fluid">
            <div className='custom-card-ui'>
                <div className="row">

                    <div className="col-12" style={{ zIndex: '1' }}>
                        <Card>
                            <Card.Header>
                                <Form onSubmit={onSearchClick} ref={formRef} id='searchForm' onReset={onClearSearch}>
                                    <div className='d-flex flex-row justify-content-end align-items-end flex-wrap'>

                                        <TextField
                                            style={{ width: '130px' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleClearSearchData('client_name')}>
                                                        <AiOutlineClose size={"0.6em"} />
                                                    </IconButton>
                                                ),
                                            }}
                                            id="standard-basic" name="client_name" value={search?.client_name} onChange={(e) => handleSearchChange(e)} label="Client Name" className='me-2 ' variant="standard" />
                                        <TextField
                                            style={{ width: '130px' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleClearSearchData('facility_name')}>
                                                        <AiOutlineClose size={"0.6em"} />
                                                    </IconButton>
                                                ),
                                            }}
                                            id="standard-basic" name="facility_name" value={search?.facility_name} onChange={(e) => handleSearchChange(e)} label="Facility Name" className='me-2 ' variant="standard" />
                                        <TextField
                                            style={{ width: '130px' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleClearSearchData('lockbox_no')}>
                                                        <AiOutlineClose size={"0.6em"} />
                                                    </IconButton>
                                                ),
                                            }}
                                            id="standard-basic" name="lockbox_no" value={search?.lockbox_no} onChange={(e) => handleSearchChange(e)} label="Lockbox No" className='me-2 ' variant="standard" />

                                        <Tooltip disableInteractive title="Deposit Date" placement="top">
                                            <div className="input-group input-group-sm global-input-group me-2">
                                                <Flatpickr
                                                    ref={flatpickrUploadRef}
                                                    className="form-control dash-filter-picker flatpickr-input active global-date-input"
                                                    value={search?.date}
                                                    // defaultValue={Date()}
                                                    placeholder='MM/DD/YYYY to MM/DD/YYYY'
                                                    onClose={(ist) => {
                                                        if (ist?.length == 1) {
                                                            setSearch({ ...search, date: [search.date[0], search.date[0]] })

                                                        }
                                                    }}
                                                    onChange={(ist, date) => handleDateChange({ ist: ist, date: date, type: 'date' })}
                                                    options={{
                                                        dateFormat: "m/d/Y",
                                                        mode: 'range',
                                                        altInputClass: 'hide',
                                                        disable: [
                                                            function (date) {
                                                                if (date.getDay() === 0) {
                                                                    // return true
                                                                    // return !(date.getDay() % 7);
                                                                }
                                                            }
                                                        ]
                                                    }}

                                                />
                                                <div className="input-group-text global-input-text" onClick={() => { flatpickrUploadRef.current.flatpickr.toggle(); }}>
                                                    <i className="ri-calendar-2-line"></i>
                                                </div>
                                                <button onClick={(e) => handleClearDate('date')} class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-78trlr-MuiButtonBase-root-MuiIconButton-root" tabindex="0" type="button"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.6em" width="0.6em" xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
                                            </div>
                                        </Tooltip>
                                        <Tooltip disableInteractive title="Exclude Completed" placement="top">

                                            <div className='custome-toggle-switch-btn ms-2 me-2'>
                                                <label class="switch">
                                                    <input checked={search.is_exclude} onChange={(e) => {
                                                        setSearch({
                                                            ...search,
                                                            is_exclude: e.target.checked
                                                        })
                                                    }} type="checkbox" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </Tooltip>
                                        <div className='d-flex flex-row align-items-center mt-2'>
                                            <Button size='medium' className=' me-2' variant="outlined" style={{ color: "#1ba1a6", borderColor: '#1ba1a6' }} type='submit' startIcon={<Search />}>Search</Button>
                                            <Button size='medium' className='' variant="outlined" style={{ color: "#1ba1a6", borderColor: '#1ba1a6' }} type='reset' startIcon={<Clear />}>Clear</Button>
                                        </div>
                                        <div className='d-flex flex-row align-items-center justify-content-end gap-2  me-2 mb-1 ms-2 mt-2'>
                                            <Tooltip title="Refresh Data" placement='top' disableInteractive>
                                                <div onClick={() => setUpdate(!update)} role='button'>
                                                    <Refresh size={22} style={{ color: '#1ba1a6' }} />
                                                </div>
                                            </Tooltip>
                                            {/* {openReasonPopup() ?
                                                <Tooltip title="Export Data" placement='top' disableInteractive>
                                                    <div onClick={() => data?.data?.results?.length ? setPopup({ ...popup, view: true, type: 'EXPORT' }) : createNotification('error', 'No data found')} role='button'>
                                                        <AiFillFileText size={22} style={{ color: '#1ba1a6' }} />
                                                    </div>
                                                </Tooltip>
                                                :
                                                <Tooltip title="Export Data" placement='top' disableInteractive>
                                                    <div onClick={() => exportFile({ url: 'payment/get-payments?is_file=1&', model_number: 4, type: "csv", master: searchParams.get('rem'), search: searchedKeys, matched: searchedStatus?.value, setLoading: setLoading })} role='button'>
                                                        <AiFillFileText size={22} style={{ color: '#1ba1a6' }} />
                                                    </div>
                                                </Tooltip>
                                            } */}
                                        </div>
                                    </div>
                                </Form>
                            </Card.Header>
                            <Card.Body>
                                {datatable ?
                                    <DataTable
                                        data={data?.data?.results}
                                        className="custom-datatable"
                                        columns={columns}
                                        progressPending={loading}
                                        pagination
                                        paginationDefaultPage={pageno}
                                        paginationServer
                                        highlightOnHover={true}
                                        pointerOnHover={true}
                                        paginationRowsPerPageOptions={[25, 50, 100]}
                                        fixedHeader={true}
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={limit}
                                        onChangeRowsPerPage={onPerPageChange}
                                        onChangePage={onPageChange}
                                        theme="solarized"
                                        customStyles={queueDatatableCustomStyles}
                                        conditionalRowStyles={conditionalRowStyles}
                                        striped={true}
                                        onRowClicked={handleRowClick}
                                        responsive={true}
                                        sortServer
                                        onSort={handleSort}
                                    />
                                    :
                                    <></>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <Modal show={popup?.view} size="lg" onHide={() => setPopup(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='detail-modal'>
                        <div>
                            <label className="form-label">Reason</label>
                            <textarea
                                type="text"
                                maxLength={500}
                                rows={5}
                                className="form-control"
                                name="user_name"
                                placeholder="Enter Reason"
                                onChange={(e) => setPopup({ ...popup, reason: e.target.value })}
                                value={popup?.reason}
                            />
                            {popup?.error ? (
                                <div className='text-danger p-2'>This field is required</div>
                            ) : (
                                <></>
                            )}

                            <div className="hstack gap-2 justify-content-end mt-2">

                                <ReactiveButton
                                    style={{ backgroundColor: '#b7babf' }}
                                    idleText="Cancel"
                                    onClick={() => setPopup({
                                        view: false,
                                        reason: '',
                                        data: '',
                                        type: ''
                                    })}
                                />

                                <ReactiveButton
                                    style={{ backgroundColor: '#1ba1a6' }}
                                    className="mr-2"
                                    type={'button'}
                                    buttonState={(loading?.loading) ? loading?.type : 'idle'}
                                    idleText="Submit"
                                    loadingText="Loading"
                                    successText="Done"
                                    disabled={false}
                                    onClick={submitReason}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NonRevarcDashboard


