import axiosInstance from "src/helpers/axiosInstance";


export const changeUserPassword = (props) => onResponse =>  {

    try {
        axiosInstance.post("/users/change-password", {
            old_password: props.data.get("old_password"),
            password: props.data.get("password"),
            confirm_password: props.data.get("confirm_password")
          })
        .then((response) => {
            onResponse(response);
        })
        .catch((err) => {
            onResponse(err.response);
        });
            
    } catch (error) {
        
    }
};

