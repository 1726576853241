import React, { useEffect, useState } from 'react'
import { FiHome } from 'react-icons/fi';
import { AiOutlineFileProtect, AiOutlineFileSync, AiOutlineAudit } from 'react-icons/ai';
import { TbReportAnalytics, TbReportSearch, TbReportMedical, TbUsers, TbSmartHome } from 'react-icons/tb';
import { VscOutput } from 'react-icons/vsc';
import { FaUsersCog } from 'react-icons/fa';
import { MdCompare } from 'react-icons/md';
import { RiSearch2Fill, RiUserSettingsLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { SETTINGS, NON_REVARC_DASHBOARD, DASHBOARD, ENCOUNTERS, ENCOUNTER_FILES, OUTPUT_FILES, NACHA_FILES, AUDIT_LOG, PAYMENTS, CLIENT_MANAGEMENT_CLIENTS, PAYMENTS_FILES, REASSOCIATION_MATCHED_REMITTANCES, REASSOCIATION_ORPHAN, REASSOCIATION_UNMATCHED_REMITTANCES, REASSOCIATION_REPORTS, USER_MANAGEMENT_ROLES, USER_MANAGEMENT_GROUPS, USER_MANAGEMENT_USERS, NACHA_PAYMENTS, BAI_FILES, BAI_PAYMENTS, SEARCH_PAGE, PAYMENT_835_LITE, PATIENT_PAY_URL, CORRESPONDANCE_URL, LOCKBOX_URL } from '../../constants/routeName';
import Logo from '../../static/images/revarc_logo.png'
import { reactLocalStorage } from 'reactjs-localstorage';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { decryptResponse } from 'src/components/common/EncryptDecrypt';

import { decryptBackEndResponse } from 'src/components/common/EncryptDecrypt';


const secretKey = process.env.REACT_APP_DECRYPT_KEY;




const NavBar = () => {


    const [revarc_is_available, setRevarc_is_available] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [height, setHeight] = useState(window.innerHeight);
    const userLogo = reactLocalStorage.get('user_logo');
    // const user = reactLocalStorage.getObject('user',{})
    const user_type = reactLocalStorage.getObject('user_type', {});
    const user = decryptResponse(reactLocalStorage.getObject('user', {}));


    useEffect(() => {
        const userData = decryptResponse(reactLocalStorage.getObject('user', {}));
        const datas = reactLocalStorage.get('permissions', '')

        if (datas !== 'undefined' && datas) {
            const encryptedPermissionData = JSON.parse(datas)
            const outresponsedata = decryptBackEndResponse({ base64Key: secretKey, encryptedData: encryptedPermissionData })
            console.log(outresponsedata);
            setPermissions(outresponsedata)

        }
        const revarc_available = reactLocalStorage.getObject('revarc_available', true);
        console.log(revarc_available);
        setRevarc_is_available(revarc_available)

    }, [])


    return (
        <div className="app-menu navbar-menu">
            <div className="navbar-brand-box">
                <a href={revarc_is_available ? DASHBOARD : NON_REVARC_DASHBOARD} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={userLogo && userLogo !== '' ? userLogo : user?.logo && user?.logo !== '' ? user?.logo : Logo} alt="" height="20" />
                    </span>
                    <span className="logo-lg">
                        <img src={userLogo && userLogo !== '' ? userLogo : user?.logo && user?.logo !== '' ? user?.logo : Logo} alt="" height="40" width="140" />
                    </span>
                </a>

                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                    id="vertical-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>

            <SimpleBar style={{ maxHeight: height - 120 }}>
                <ul className="navbar-nav" id="navbar-nav">
                    <li className="menu-title"><span data-key="t-menu"></span></li>

                    {revarc_is_available ?

                        <>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to={DASHBOARD}>
                                    <FiHome />  <span data-key="t-dashboard">Dashboard</span>
                                </NavLink>
                            </li>
                            {user_type == 3 | user_type == 2 ?
                                <li className="nav-item">
                                    <NavLink className="nav-link menu-link" to={NON_REVARC_DASHBOARD}>
                                        <TbSmartHome />  <span data-key="t-dashboard">File Monitoring</span>
                                    </NavLink>
                                </li>
                                :
                                <></>
                            }
                            {permissions.includes('access_patient_encounter_search') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <NavLink className="nav-link menu-link" to={SEARCH_PAGE}>
                                        <RiSearch2Fill />  <span data-key="t-dashboard">Patient Encounter Search</span>
                                    </NavLink>
                                </li>
                                :
                                <></>
                            }
                            {permissions.includes('access_claim_upload') | permissions.includes('access_claims') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarEncounters" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarEncounters">
                                        <TbReportAnalytics />  <span data-key="t-encounters">837 Management</span>
                                    </a>
                                    <div className="collapse menu-dropdown " id="sidebarEncounters">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_claim_upload') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={ENCOUNTER_FILES} className="nav-link" data-key="t-calendar"> Claim Upload </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                            {permissions.includes('access_claims') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={ENCOUNTERS} className="nav-link" data-key="t-chat"> Claims </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }

                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }
                            {permissions.includes('access_remittance_upload') | permissions.includes('access_remittances') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarPayments" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPayments">
                                        <TbReportSearch /> <span data-key="t-encounters">835 Management</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarPayments">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_remittance_upload') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={PAYMENTS_FILES} className="nav-link" data-key="t-calendar">Remittance Upload</NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                            {permissions.includes('access_remittances') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={PAYMENTS} className="nav-link" data-key="t-chat"> Remittances </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }
                            {permissions.includes('access_835_lite') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebar835Lite" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebar835Lite">
                                        <TbReportAnalytics />  <span data-key="t-835Lite">835 Lite</span>
                                    </a>
                                    <div className="collapse menu-dropdown " id="sidebar835Lite">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_835_lite') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={PAYMENT_835_LITE} className="nav-link" data-key="t-calendar"> Payment 835 Lite </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }



                                        </ul>
                                    </div>
                                </li>
                                :
                                <></>
                            }
                            {permissions.includes('access_patient_pay') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarPatientPay" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPatientPay">
                                        <TbReportAnalytics />  <span data-key="t-PatientPay">Patient Pay</span>
                                    </a>
                                    <div className="collapse menu-dropdown " id="sidebarPatientPay">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_patient_pay') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={PATIENT_PAY_URL} className="nav-link" data-key="t-calendar"> Patient Pay </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }



                                        </ul>
                                    </div>
                                </li>
                                :
                                <></>
                            }

                            {permissions.includes('access_correspondence') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarcorrespondance" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarcorrespondance">
                                        <TbReportAnalytics />  <span data-key="t-correspondance">Correspondence</span>
                                    </a>
                                    <div className="collapse menu-dropdown " id="sidebarcorrespondance">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_correspondence') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={CORRESPONDANCE_URL} className="nav-link" data-key="t-calendar"> Correspondence </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }


                                        </ul>
                                    </div>
                                </li>
                                :
                                <></>
                            }
                            {permissions.includes('access_supplemental_output_files') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarLockbox" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarLockbox">
                                        <TbReportAnalytics />  <span data-key="t-Lockbox">Supplemental Output</span>
                                    </a>
                                    <div className="collapse menu-dropdown " id="sidebarLockbox">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_supplemental_output_files') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={LOCKBOX_URL} className="nav-link" data-key="t-calendar">Supplemental Output Files</NavLink>
                                                </li>
                                                :
                                                <></>
                                            }



                                        </ul>
                                    </div>
                                </li>
                                :
                                <></>
                            }

                            {permissions.includes('access_nacha_upload') | permissions.includes('access_nacha_payments') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarACHManagement" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarACHManagement">
                                        <AiOutlineFileSync /> <span data-key="t-encounters">ACH Management</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarACHManagement">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_nacha_upload') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={NACHA_FILES} className="nav-link" data-key="t-calendar">NACHA Upload</NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                            {permissions.includes('access_nacha_payments') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={NACHA_PAYMENTS} className="nav-link" data-key="t-chat"> NACHA Payments </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }










                            {permissions.includes('access_matched_remittances') | permissions.includes('access_unmatched_remittances') | permissions.includes('access_unmatched_payments') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarReassociation" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarReassociation">
                                        <MdCompare /> <span data-key="t-encounters">Reassociation</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarReassociation">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                {permissions.includes('access_matched_remittances') | (user?.is_superuser && user_type == 1) ?
                                                    <NavLink to={REASSOCIATION_MATCHED_REMITTANCES} className="nav-link" data-key="t-chat"> Matched Remittances </NavLink>
                                                    :
                                                    <></>
                                                }
                                                {permissions.includes('access_unmatched_remittances') | (user?.is_superuser && user_type == 1) ?
                                                    <NavLink to={REASSOCIATION_UNMATCHED_REMITTANCES} className="nav-link" data-key="t-chat"> Unmatched Remittances </NavLink>
                                                    :
                                                    <></>
                                                }
                                                {permissions.includes('access_unmatched_payments') | (user?.is_superuser && user_type == 1) ?
                                                    <NavLink to={REASSOCIATION_ORPHAN} className="nav-link" data-key="t-chat"> Unmatched Payments </NavLink>
                                                    :
                                                    <></>
                                                }

                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }
                            {permissions.includes('access_bai_file_upload') | permissions.includes('access_bai_activity') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarBAIManagement" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarBAIManagement">
                                        <AiOutlineFileProtect /> <span data-key="t-encounters">BAI Management</span>

                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarBAIManagement">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_bai_file_upload') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={BAI_FILES} className="nav-link" data-key="t-calendar">BAI File Upload</NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                            {permissions.includes('access_bai_activity') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={BAI_PAYMENTS} className="nav-link" data-key="t-chat"> BAI Activity </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }

                            {permissions.includes('access_summary') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarReports" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarReports">
                                        <TbReportMedical /> <span data-key="t-encounters">Reports</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarReports">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <NavLink to={REASSOCIATION_REPORTS} className="nav-link" data-key="t-chat"> Summary </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }
                            {permissions.includes('access_output_files') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <NavLink className="nav-link menu-link" to={OUTPUT_FILES}>
                                        <VscOutput />  <span data-key="t-dashboard">Output Files</span>
                                    </NavLink>
                                </li>
                                : <></>
                            }
                            {permissions.includes('access_roles') | permissions.includes('access_groups') | permissions.includes('access_users') | (user?.is_superuser && user_type == 1) ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebaruserManagement" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebaruserManagement">
                                        <FaUsersCog /> <span data-key="t-encounters">User Management</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebaruserManagement">
                                        <ul className="nav nav-sm flex-column">
                                            {permissions.includes('access_roles') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={USER_MANAGEMENT_ROLES} className="nav-link" data-key="t-chat"> Roles </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                            {permissions.includes('access_groups') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={USER_MANAGEMENT_GROUPS} className="nav-link" data-key="t-chat"> Groups </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                            {permissions.includes('access_users') | (user?.is_superuser && user_type == 1) ?
                                                <li className="nav-item">
                                                    <NavLink to={USER_MANAGEMENT_USERS} className="nav-link" data-key="t-chat"> Users </NavLink>
                                                </li>
                                                :
                                                <></>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                : <></>
                            }


                            {user_type == 1 ?
                                <li className="nav-item">
                                    <a className="nav-link menu-link" href="#sidebarclientManagement" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarclientManagement">
                                        <TbUsers /> <span data-key="t-encounters">Client Management</span>
                                    </a>
                                    <div className="collapse menu-dropdown" id="sidebarclientManagement">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <NavLink to={CLIENT_MANAGEMENT_CLIENTS} className="nav-link" data-key="t-chat"> Clients </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                :
                                <></>
                            }
                            {(permissions.includes('access_audit_log')) | ((user?.is_superuser || user?.is_superuser_admin) && user_type == 1) ?
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link menu-link" to={AUDIT_LOG}>
                                            <AiOutlineAudit />  <span data-key="t-dashboard">Audit Log</span>
                                        </NavLink>
                                    </li>
                                </>
                                : <></>
                            }


                            {user?.is_superuser || user?.is_superuser_admin ?
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link menu-link" to={SETTINGS}>
                                            <RiUserSettingsLine />  <span data-key="t-dashboard">Settings</span>
                                        </NavLink>
                                    </li>
                                </>
                                : <></>
                            }



                        </>

                        :
                        <>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to={NON_REVARC_DASHBOARD}>
                                    <FiHome />  <span data-key="t-dashboard">File Monitoring</span>
                                </NavLink>
                            </li>
                        </>
                    }


                </ul>
            </SimpleBar>
        </div>
    )
}

export default NavBar
