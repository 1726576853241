import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux'
import store from './store';
import { ToastContainer } from 'react-toastify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './static/css/bootstrap.min.css'
import './static/css/icons.min.css'
import './static/css/app.min.css'
import './static/css/datatable.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <HelmetProvider>
        <ReduxProvider store={store}>

            <React.Fragment>
                <BrowserRouter>
                    <App />
                    <Helmet>
                        <script src="./static/libs/bootstrap/js/bootstrap.bundle.min.js" type="text/javascript"></script>
                        <script src="./static/libs/simplebar/simplebar.min.js" type="text/javascript"></script>
                        <script src="./static/libs/node-waves/waves.min.js" type="text/javascript"></script>
                        <script src="./static/libs/feather-icons/feather.min.js" type="text/javascript"></script>
                        <script src="./static/js/pages/plugins/lord-icon-2.1.0.js" type="text/javascript"></script>
                        <script src="./static/libs/swiper/swiper-bundle.min.js" type="text/javascript"></script>
                        {/* <script src="./static/js/app.js" type="text/javascript"></script> */}
                        
                        </Helmet>
                    <ToastContainer />

                </BrowserRouter>
            </React.Fragment>


        </ReduxProvider>
    </HelmetProvider>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
